.animateClassTop {
  position: relative;
  animation: animatetop 0.4s;
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}

.animateClassLeft {
  position: relative;
  animation: animateleft 0.4s;
}

@keyframes animateleft {
  from {
    left: -300px;
    opacity: 0;
  }

  to {
    left: 0;
    opacity: 1;
  }
}

.animateClassRight {
  position: relative;
  animation: animateright 0.4s;
}

@keyframes animateright {
  from {
    right: -300px;
    opacity: 0;
  }

  to {
    right: 0;
    opacity: 1;
  }
}

.animateClassBottom {
  position: relative;
  animation: animatebottom 0.4s;
}

@keyframes animatebottom {
  from {
    bottom: -300px;
    opacity: 0;
  }

  to {
    bottom: 0;
    opacity: 1;
  }
}

.animateClassZoom {
  animation: animatezoom 0.6s;
}

@keyframes animatezoom {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

.reveal {
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: all 0.5s ease;
}

.reveal.active {
  transform: translateY(0);
  opacity: 1;
}

.profileContent {
  color: var(--cream);
  font-size: var(--h3);
  text-align: justify;
  line-height: 1.7;
}

.profileParagraph {
  margin: 3% 0;
}

.profileCaption {
  color: var(--light);
  font-size: var(--h2);
  /* text-align: center;  */
}

.profileHistoryTitle {
  color: var(--light);
  font-size: var(--h1);
}

.philosohy-title {
  margin-top: 3%;
}

.CorporateImageAlignMent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2%;
}

.corporateNationalImage {
  border-radius: 10%;
  width: 100%;
  height: 200px;
}

.CorporateSpace {
  margin-bottom: 5%;
}

.about-imgbox {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2%;
}

.imageabout {
  width: 60%;
  margin: 2% 0;
}

.imageProfile {
  width: 60%;
  margin: 2% 0;
}

.documentryVideo {
  background-color: var(--white);
  padding: 2%;
  margin: 4%;
  border-radius: 10px;
}

@media screen and (max-width: 800px) {
  .documentryVideo {
    background-color: var(--white);
    padding: 3%;
    margin-bottom: 10%;
  }
}