.notFoundBox {
  height: 20vmax;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: var(--cream);
}

.notFoundBox > div {
  font-size: 2vmax;
}
.notFoundBox > p {
  font-size: 1vmax;
  color: var(--pencil);
  cursor: pointer;
  transition: all 0.5s;
}
.notFoundBox > p:hover {
  color: var(--cream);
}
