.alignMent {
  flex-direction: column;
  background-color: var(--dark) !important;
}

.logoNavbar {
  width: 100px;
  padding: 5px;
}

.NavLogoName {
  display: none;
}

.navbar-nav {
  flex: 1;
  margin: auto !important;
  display: flex;
  justify-content: right;
}

.menuNav {
  text-align: right;
}

/* .navbar-light .navbar-nav .nav-link:hover {
  border-radius: 5px;
  transition: 0.5s;
} */

.colorFont .navbar-nav .nav-link {
  color: var(--brown) !important;
  transition: 0.5s;
  padding-right: 50px;
  padding-left: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 16px;
}

.colorNavFont .navbar-nav .nav-link {
  color: var(--logoColor) !important;
  transition: 0.5s;
  padding-right: 50px;
  padding-left: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 16px;
}

.colorNavFont .navbar-nav .nav-link.active {
  color: var(--cream) !important;
}

.colorNavFont .navbar-nav .nav-link:hover {
  color: var(--cream) !important;
}

.colorNavFontAfter .navbar-nav .nav-link:hover {
  color: var(--dark) !important;
}

/* 
.navbar-light .navbar-nav .nav-link:hover {
  color: var(--black) !important;
} */

.blankNavSpace {
  height: 100px;
  background-color: var(--dark);
}

.DropDownNav {
  background-color: var(--dark) !important;
  /* border: 1px solid var(--cream); */
}

.DropDownNav a {
  color: var(--logoColor) !important;
}

.DropDownNav a:hover {
  background-color: var(--pencil) !important;
  color: var(--white) !important;
}

.dash i {
  color: var(--pencil);
}

.navbar-light .navbar-toggler {
  border: 1px solid var(--cream);
}

.btn-call-font-default a {
  color: var(--logoColor);
}

.btn-call-font-default a:hover {
  color: var(--light);
}

.btn-call-font-default-hover a {
  color: var(--brown) !important;
}

.btn-call-font-default-hover a:hover {
  color: var(--black) !important;
}

.btn-call-font-add a {
  color: var(--black);
}

.colorChange {
  transition: 0.5s ease-in-out;
  /* margin: 10px 0; */
  /* opacity: 0.98; */
  background-color: var(--cream) !important;
}

.NavbuttonRight {
  display: none;
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--cream) !important;
  text-decoration: none;
  background-color: var(--black);
}

.dropdown-item {
  margin: 0;
}

.navLogoIcon {
  height: 50px;
}

@media only screen and (max-width: 800px) {
  .blankNavSpace {
    height: 90px;
  }

  .NavLogoName {
    display: block;
  }

  #navLogo {
    display: none;
  }

  .logoNavbar {
    width: 80px;
  }
}

@media screen and (min-width: 820px) and (max-width: 1120px) {
  .blankNavSpace {
    height: 90px;
  }
}