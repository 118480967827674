.bg-image {
    background: url("https://admin.argiltiles.com/public/slider/1661685400927088234.jpg") no-repeat;
    background-size: cover;
    background-color: grey;
    background-blend-mode: multiply;
    color: #CAC3BB;
    padding: 0 6% 6% 6%;
}

.bg-image-enhance {
    background: url("../../../../Assets/enhance/enhance-bg.jpg") no-repeat;
    background-size: 100% 100%;
    background-color: grey;
    background-blend-mode: multiply;
    color: #CAC3BB;
    padding: 0 8% 8% 8%;
}

.enhance-bg {
    margin-top: 5%;
    position: relative;
    width: 100%;
    height: auto;
}

.enhance-text {
    position: absolute;
    font-size: 2rem;
    text-align: center;
    width: 100%;
    top: 0;
    padding-top: 10px;
    color: var(--light);
}


@media only screen and (max-width: 768px) {
    .bg-image-enhance {
        background-size: cover;
    }
    .contact-us-img{
        display: none;
    }
}


.customAccordion .accordion-item button{
    background-color: var(--dark) !important;
    color: white !important;
    border:0 !important;
    box-shadow: none;
}

.customAccordion .accordion-item{
    margin-bottom: 20px; 
}

.custom-body{
    border:1px solid white !important;
    margin-bottom: 20px;
}

.customAccordion .accordion-header{
    border:1px solid white !important;
}

.customAccordion .accordion-item{
    background-color: var(--dark) !important;
    color: white;
}

.section-contact-us{
    display: flex;
    background-color: #1f1f1f;
}

.contact-us-box{
    color: var(--light);
    padding: 5%;
}

.quartz-stone-pointer{
    cursor: pointer;
}