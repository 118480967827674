@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import "~react-image-gallery/styles/css/image-gallery.css";

@font-face {
  src: local("MyFont"), url(./Argil\ Fonts.rar) format("rar");
  font-family: "MyFont";
}

body {
  font-family: "MyFont", sans-serif;
  background-color: var(--black);
  scroll-behavior: smooth;
}

/* HOME CSS START*/
tr {
  margin: 5% 0;
}

th {
  font-size: var(--h3);
  color: var(--white);
}

div .about-redirect {
  color: var(--brown) !important;
  text-decoration: none;
}

.home-heading {
  font-size: var(--h1);
  color: var(--light);
  font-weight: 500;
  margin: 4% 0;
  text-align: center;
}

.home-product-heading {
  font-size: var(--h1);
  color: var(--light);
  margin: 2% 0;
  cursor: pointer;
  background: linear-gradient(to right, var(--dark), var(--dark)),
    linear-gradient(to right, var(--cream), var(--light), var(--cream));
  background-size: 100% 2px, 0 2px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 550ms;
  width: 30%;
}

.home-product-heading:hover {
  background-size: 0 2px, 50% 2px;
}

.showMoreProducts {
  width: 25%;
  margin: 2vmax auto;
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: var(--cream);
  color: var(--dark);
  cursor: pointer;
  padding: 0.5vmax;
  transition: all 0.5s;
}

.showMoreProducts:hover {
  background-color: var(--brown);
  color: var(--cream);
}

.home-img {
  width: 100%;
  /* margin: 10% 0%; */
}

.home-img-box {
  height: 80%;
  display: flex;
  justify-content: flex-end;
}

.homeSpace {
  color: var(--light);
  margin: 5% 0;
}

/* .homeAdjust {
  margin: 2% 2%;
} */
.sub-heading {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1px;
  color: var(--primary);
}

.content {
  color: var(--grey);
  font-size: var(--h3);
  line-height: 1.5;
  text-align: justify;
  margin-bottom: 6%;
}

.content span {
  color: var(--cream);
  border-bottom: 1px solid var(--dark);
  transition: all 0.5s;
  cursor: pointer;
}

.content span:hover {
  border-bottom: 1px solid var(--cream);
}

.button a {
  color: var(--primary) !important;
  border: 1px solid var(--primary);
  padding: 5px;
  box-shadow: 1px 1px 6px var(--grey);
  margin-top: 20px;
  display: flex;
  width: 30%;
  justify-content: center;
  border-radius: 5px;
}

.button:hover a {
  background-color: var(--grey);
  color: var(--default) !important;
}

@media screen and (max-width: 760px) {

  /* .home-heading {
    font-size: 35px;
  } */
  .home-img {
    height: 300px;
  }

  .home-product-heading {
    width: 100%;
    text-align: center;
  }

  .home-product-heading:hover {
    transform: translateX(0);
  }

  .showMoreProducts {
    width: 55%;
  }
}

/* HOME CSS END*/

/* .footer-class-link {
  color: var(--pencil);
  text-align: center;
  cursor: pointer;
}
.footer-class-link:hover {
  border-bottom: 1px solid var(--white);
  transition: 0.5s;
} */
/* CONTACT CSS START*/
.contact-inquiry-form {
  margin-left: 16%;
  width: 70%;
}

.contactContent {
  color: var(--cream);
  font-size: 15px;
  margin-left: 10%;
  text-align: justify;
}

.contactImg {
  /* width: 100%; */
  height: 80vh;
  margin-bottom: 5%;
}

.contact-form-box {
  margin: 3% 0%;
  /* box-shadow: 1px 2px 3px var(--cream); */
  background-color: var(--dark);
}

.contactHeading {
  /* margin-top: 5%; */
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  color: var(--cream);
}

.contactPage {
  margin-top: 55px;
}

/* .left-content {
  height: 550px;
} */
.right-content {
  display: flex;
  justify-content: center;
}

.contact-heading {
  font-size: 30px;
  color: var(--light);
}

.contact-section {
  color: var(--white);
  display: flex;
  justify-content: left;
}

.card-header {
  color: var(--cream);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.contact-form {
  border: 1 px solid var(--primary);
  border-radius: 12px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.cnt-name {
  border: 1 px solid var(--black);
}

.contact-rigth-forms {
  height: 530px;
  border-radius: 12px;
  box-shadow: 1px 2px 2px 2px var(--cream);
  width: 70%;
  /* margin: 2% 12%; */
  padding: 1% 2%;
}

.form-title {
  color: var(--cream);
  font-size: 20px;
  margin: 0px 12px 0px;
}

.input {
  width: 95%;
  padding: 8px 6px;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 0px 10px;
  margin-bottom: 20px;
  resize: vertical;
  font-size: 15px;
  justify-content: center;
  color: var(--white) !important;
  background-color: var(--pencil);
}

.contact-textarea {
  width: 95%;
}

.form-submit {
  border: 1px solid var(--primary);
  background-color: var(--pencil);
  color: var(--cream);
  padding: 8px 25px;
  border-radius: 5px;
  transition: 0.5s;
  margin: 0px 8px;
}

.form-submit:hover {
  color: var(--cream);
  background-color: var(--brown);
}

.address-card {
  border: 1px solid var(--white);
  border-radius: 8px;
  color: var(--white);
}

.contactMap {
  margin: 4% 0;
}

@media screen and (max-width: 760px) {
  .contactPage {
    margin-top: 15px;
  }

  .contactMap {
    margin: 10% 0;
  }

  .card-border-light {
    margin: 45px;
  }

  .right-content {
    height: 600px;
    width: 100%;
  }

  .footerLogoBox {
    margin-top: 10px;
  }

  .closebtn {
    position: absolute;
    top: 0;
    right: 0;
    margin: 5%;
    height: 20px;
    width: 20px;
    font-size: 20px;
  }

  .paddingModal {
    padding: 30px;
  }

  .contact-rigth-forms {
    margin: 2% 5%;
    width: 90%;
  }
}

@media screen and (min-width: 820px) and (max-width: 1182px) {
  .contact-rigth-forms {
    width: 100%;
  }

  .contactPage {
    margin: 2% 0;
  }
}

@media screen and (min-width: 782px) {
  .card-border-light {
    margin-left: 45px;
  }

  .left-right-contact {
    height: 550px;
  }

  .modalShow {
    top: 10;
    left: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    display: block;
  }

  .contactPage {
    margin: 3% 0;
  }
}

/* CONTACT CSS END*/

/* FOOOTER CSS */

.customFooter {
  background-color: var(--cream);
  color: var(--black);
}

.footerLogoBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
}

.footerImageLogo {
  cursor: pointer;
  width: 15vmax;
  padding: 5%;
}

.footerLogoTitle {
  color: var(--lightGrey);
  margin-top: 20px;
  font-size: 20px;
  letter-spacing: 2px;
}

.footerTitle {
  font-size: 20px;
  margin: 20px 0;
  transition: all 0.5s;
  cursor: pointer;
}

.footerTitle:hover {
  color: var(--brown);
}

.addressFooter {
  font-size: 15px;
  margin-bottom: 12px;
  transition: all 0.5s;
}

.addressFooter:hover {
  color: var(--brown);
}

.addressFooter i {
  font-size: 15px;
  transition: all 0.5s;
  color: var(--dark);
  margin-right: 5px;
}

.addressFooter i:hover {
  color: var(--brown);
}

.footerEnd {
  display: flex;
  height: 100px;
  justify-content: space-between;
  align-items: center;
  background-color: var(--black);
  color: var(--pencil);
  letter-spacing: 1px;
  padding: 0 20px;
}

.footerLinkBox {
  margin-bottom: 25px;
  line-height: 3;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  transition: all 0.5s;
}

.footerLinkBox div {
  transition: all 0.5s;
  text-decoration: underline;
  text-decoration-color: var(--cream);
}

.footerLinkBox div:hover {
  color: var(--brown);
  transform: translateY(-2px);
  transition: all 0.5s;
  font-weight: 500;
  text-decoration-color: var(--brown);
}

.footerImageApp {
  cursor: pointer;
  margin: 10px 0;
  width: 10vmax;
}

.appIconAlign {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.mediaIcon {
  cursor: pointer;
  margin: 20px 0;
}

.mediaIcon i {
  padding-right: 20px;
  font-size: 2.5vh;
}

.footer-class-link {
  color: var(--pencil);
  text-align: center;
  cursor: pointer;
  transition: all 0.5s;
  border-bottom: 1px solid var(--dark);
}

.footer-class-link:hover {
  color: var(--cream);
}

@media screen and (max-width: 800px) {
  .footerEnd {
    flex-direction: column;
    margin-bottom: 13%;
    padding: 5%;
    height: 70px;
  }
}

/* ================= */

/* PRODUCTS PAGE CSS */

.productMobileView {
  display: none;
}

.wallMenusProductTitle {
  letter-spacing: 0.5px;
  color: var(--white);
  margin: 1%;
}

.wallSearchAlign {
  flex-direction: row;
  display: flex;
  align-items: center;
}

.wallIcon {
  color: var(--white);
  padding-left: 10px;
}

.wallMenuSizesBox {
  margin-top: 20px;
}

.wallMenusCheckBox {
  letter-spacing: 1px;
  color: var(--grey);
  margin: 15px 0;
}

.wallMenusCheckBox label {
  color: var(--white);
  margin-left: 10px;
  margin-bottom: 5px;
}

.wallItemsBox {
  padding: 10px;
}

.homeTilesProducts:hover {
  transform: translateY(-20px);
}

.wallItemsImagBox {
  border: 0.2px solid var(--pencil);
  padding: 10px;
  margin: 20px;
  border-radius: 5px;
  transition: 0.5s;
  position: relative;
  cursor: pointer;
}

.wallItemsImages {
  width: 100%;
}

.imageTitle {
  color: var(--white);
  letter-spacing: 1px;
  font-size: 18px;
}

.imageTitle-quartz {
  color: var(--white);
  letter-spacing: 1px;
  font-size: 18px;
  text-align: center;
}

.iconAlignMent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.wallItemsImagBox:hover .hoverImages {
  opacity: 1;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  padding-bottom: 70%;
  transition: 0.5s;
  border: 1px solid var(--white);
}

.addFavIcon {
  color: var(--white);
  font-size: 18px;
  transition: 0.5s;
}

.addFavIcon:hover {
  color: palevioletred;
  transition: 0.5s;
}

.InfoIcon {
  color: var(--default);
  font-size: 18px;
  transition: 0.5s;
}

.InfoIcon:hover {
  transition: 0.5s;
  color: var(--primary);
}

.hoverImages {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  transition: 0.5s;
  width: 100%;
  height: 100%;
  margin: -10px;
  opacity: 0;
  position: absolute;
  border-radius: 5px;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
}

.sizesWall-Modal {
  overflow-Y: scroll;
  height: 65vh;
}

.modalShow {
  height: 0;
  width: 100%;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  background-color: var(--black);
  overflow-y: hidden;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  margin: 50px;
  cursor: pointer;
  color: var(--white);
  transition: 0.5s;
  height: 40px;
  width: 40px;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.closebtn:hover {
  color: var(--black);
  background-color: var(--white);
  transition: 0.5s;
}

.fixedIcon {
  position: fixed;
  bottom: 0;
  right: 25px;
  margin: 4%;
  z-index: 9998;
  cursor: pointer;
}

.circleButton:hover {
  border: 2px solid var(--pencil);
  transition: 0.5s;
  color: var(--pencil);
}

.circleButton {
  background-color: var(--white);
  color: var(--black);
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--white);
  transition: 0.5s;
}

.searchButtonModal {
  display: flex;
  border-radius: 5px;
  background-color: var(--cream);
  color: var(--white);
  padding: 5px;
  align-items: center;
  justify-content: center;
  margin-left: 2%;
  transition: all 0.5s;
  cursor: pointer;
}

.searchButtonModal:hover {
  background-color: var(--pencil);
  color: var(--white);
}

.wallMenusCheckBox>div> :hover {
  transition: all 0.3s;
  color: var(--white);
}

@media screen and (max-width: 830px) {
  .productMobileView {
    display: block;
    display: flex;
    padding-top: 5%;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
  }

  .modalShow {
    top: 0;
    left: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    display: block;
  }

  .closebtn {
    position: absolute;
    top: 0;
    right: 0;
    margin: 5%;
    height: 20px;
    width: 20px;
    font-size: 20px;
  }

  .paddingModal {
    padding: 40px;
  }

}

/* ================= */

/* qurtz surface */

.quartzItemsImages {
  width: 100%;
  /* height: 150px; */
}

.homeItemsImages {
  width: 100%;
}

.QuartzSurButtonAlign {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
}

.QuartzSurButton {
  display: inline-block;
  width: 10%;
}

.quartzimageTitle {
  color: var(--white);
}

/* ================= */

/* NEWS ROOM CSS START */
.newsrrom-content {
  color: var(--light);
  font-size: 25px;
  margin-top: 8%;
  justify-content: center;
  text-align: justify;
}

.newsroom-heading {
  font-size: 40px;
  color: var(--white);
  margin: 5% 0;
}

.newroom-img-box {
  margin-bottom: 10%;
}

.newroom-img {
  width: 80%;
  height: 300px;
}

/* @media screen and (max-width:800px) {
  .newsrrom-content{
    margin: 0;
  }
} */
/* NEWS ROOM CSS END*/

/* CATALOGUE CSS END*/

.pdf-heading {
  margin-top: 5%;
  margin-bottom: 2%;
  font-size: 25px;
  color: var(--white);
}

.pdf-img {
  cursor: pointer;
  width: 10%;
  margin-bottom: 5%;
}

.pdf-post {
  display: flex;
  justify-content: flex-start;
  color: var(--light);
  font-size: 17px;
}

/* CATALOGUE CSS END*/

/* PRODUCTINQUIERY CSS END*/
.product-inquiry-box {
  margin-top: 5%;
}

.pr-inq {
  margin: 1% 0%;
}

.product-heading {
  font-size: var(--h1);
  font-weight: 500 !important;
  color: var(--cream);
}

.product-details {
  color: var(--cream);
  line-height: 2;
}

.product-slider {
  width: 60%;
  height: 550px;
  border-radius: 10px;
  margin: 5%;
  padding: 2%;
  box-shadow: 2px 2px 10px var(--lightGrey);
  background-color: var(--default);
}

.product-inquery-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  /* height: 50px;
  width: 20px; */
  display: none;
}

.carousel-indicators button.active {
  background-color: var(--black) !important;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: var(--black);
  border-radius: 30%;
  box-shadow: 2px 2px 2px var(--black);
}

.carousel-indicators img {
  position: absolute;
  width: 30%;
  background-color: var(--black);
  top: 0;
  left: 0;
}

.image-gallery-slide {
  height: 75vh !important;
}

.image-gallery-slide left {
  height: 105vh !important;
}

.image-gallery-thumbnail {
  background-color: var(--black);
  margin: 5%;
  border-radius: 10px;
  transition: 0.5s;
}

.image-gallery-thumbnail:hover {
  border: 5px solid var(--brown);
  transition: 0.5s;
}

.image-gallery-thumbnail.active {
  border: 5px solid var(--brown);
}

.image-gallery-thumbnail-image {
  width: 100px;
  height: 120px;
  padding: 8%;
}

.product-inquiry-heading {
  font-size: var(--h1);
  margin: 3% 0;
  color: var(--cream);
}

.form {
  /* height: 50px; */
  position: relative;
  overflow: hidden;
  margin: 3% 0;
  width: 100%;
}

.productformBox {
  width: 92%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.filterButton {
  border-radius: 5px;
  color: var(--white);
  transition: 0.2s;
  padding: 5px;
  background-color: rgb(47, 47, 47);
  background-color: var(--pencil);
  font-size: 16px;
  font-weight: 600;
  transition: all 0.5s ease-in-out;
  min-width: 26%;
}

.filterbtn {
  display: flex;
  justify-content: space-evenly;
  margin-top: 2%;
}

.filterButton:hover {
  transition: all 0.5s ease-in-out;
  color: var(--brown);
  background-color: var(--white);
  transition: 0.2s;
}

.nextButton {
  border-radius: 8px;
  color: var(--white);
  transition: 0.2s;
  padding: 5px;
  background-color: var(--pencil);
  font-size: 16px;
  font-weight: 600;
  transition: all 0.5s ease-in-out;
  width: 15%;
}

.nextButton:hover {
  transition: all 0.5s ease-in-out;
  color: var(--dark);
  background-color: var(--cream);
  transition: 0.2s;
}

.form input {
  width: 100%;
  height: 100%;
  background-color: transparent;
  color: var(--white);
  border-radius: 8px;
  padding-top: 25px;
  padding-left: 2%;
  padding-bottom: 2%;
  border: 1px solid var(--cream);
  outline: none;
  transition: all 0.5s;
}

.form label {
  transition: all 0.5s;
  position: absolute;
  width: 100%;
  height: 100%;
  color: var(--cream);
  bottom: 0px;
  left: 0%;
  pointer-events: none;
  margin-top: -3%;
  margin-left: 5%;
}

.form label::after {
  content: "";
  position: absolute;
  border-radius: 8px;
  width: 70%;
  bottom: -3px;
  left: 0px;
  transform: translateX(-200%);
  transition: all 0.5s;
}

.content-name {
  padding: 0.5% 2%;
  position: absolute;
  bottom: 12px;
  left: 0px;
  transition: all 0.5s;
}

.form input:focus+.label-name .content-name,
.form input:valid+.label-name .content-name {
  transform: translateY(-16%);
  font-size: 14px;
  outline: none;
  color: var(--cream);
  position: absolute;
  background-color: var(--dark);
  transition: all 0.5s;
}

.form input:focus+.label-name::after,
.form input:valid+.label-name::after {
  transform: translateX(0%);
  transition: all 0.5s;
}

.button {
  border-radius: 6px;
  color: var(--black);
  background-color: var(--white);
  padding: 10px 50px;

  margin-top: 4%;
  transition: 1s;
}

.button:hover {
  background-color: var(--pencil);
  color: var(--white);
  transition: 1s;
}

.button1 {
  border-radius: 6px;
  color: var(--black);
  background-color: var(--white);
  padding: 10px 50px;
  width: 100%;
  margin-top: 4%;
  transition: 1s;
}

.button1:hover {
  background-color: var(--pencil);
  color: var(--white);
  transition: 1s;
}

.cnt-msg {
  margin: 40px 25px;
}

.conn {
  background-color: whitesmoke;
  height: 280px;
}

.txt {
  margin-top: 60px;
}

.image-gallery-slides {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 800px) {
  img.image-gallery-image {
    padding: 2% 12%;
    /* background-color: blue; */
  }

  .form {
    margin: 3% 8%;
    display: flex;
    justify-content: center;
  }

  .form label {
    position: absolute;
    width: 100%;
    height: 100%;
    color: var(--cream);
    bottom: 0px;
    left: 0%;
    pointer-events: none;
    margin-top: -1.5%;
    margin-left: 5%;
  }

  .form input:focus+.label-name .content-name,
  .form input:valid+.label-name .content-name {
    transform: translateY(-50%);
    font-size: 15px;
    outline: none;
    color: var(--cream);
    position: absolute;
    background-color: var(--black);
  }

  .form input {
    width: 100%;
    height: 100%;
    background-color: transparent;
    color: var(--white);
    border-radius: 8px;
    padding-top: 25px;
    padding-left: 2%;
    padding-bottom: 2%;
    border: 1px solid var(--cream);
    outline: none;
  }

  .product-heading {
    font-size: 25px;
    font-weight: 600 !important;
  }

  .nextButton {
    width: 40%;
  }

  .image-gallery-slide {
    height: 55vh !important;
  }

  .image-gallery-slides {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* PRODUCTINQUIERY CSS END*/

/* QUARTZINQUIERY CSS END*/

.wallItemsImagBox:hover .hoverImagesQuartz {
  opacity: 1;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  padding-bottom: 20%;
  transition: 0.5s;
}

.wallItemsImagBox:hover .hoverHomeImagesQuartz {
  opacity: 1;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  padding-bottom: 80%;
  transition: 0.5s;
}

.hoverHomeImagesQuartz {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  transition: 0.5s;
  width: 100%;
  height: 100%;
  margin: -10px;
  opacity: 0;
  position: absolute;
  border-radius: 5px;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
}

.hoverImagesQuartz {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  transition: 0.5s;
  width: 100%;
  height: 100%;
  margin: -10px;
  opacity: 0;
  position: absolute;
  border-radius: 5px;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
}

.quartz-product {
  color: var(--brown);
}

.quartz-info-details {
  border: 1px solid var(--white);
  box-shadow: 1px 2px 3px var(--white);
  background-color: var(--black);
  width: 32%;
  margin: 0 1%;
  border-radius: 12px;
  margin: 0 0%;
}

.quartz-img {
  width: 100%;
  padding: 0.5%;
  height: 450px;
  border: 1px solid rgba(250, 250, 250, 0.2);
  border-radius: 1vmax;
}

.carouselItems {
  padding: 2%;
}

.quartz-heading {
  color: var(--white);
  font-size: var(--h1);
  margin: 5% 0;
}

.qproduct-heading {
  color: var(--white);
  font-size: var(--h2);
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(250, 250, 250, 0.1);
  text-transform: uppercase;
}

.qsubheading {
  margin-top: 5%;
  font-size: var(--h3);
  color: var(--white);
}

table tr td {
  font-size: var(--h3);
  color: var(--cream);
  border: none;
}

table tr th {
  font-size: var(--h3);
  border: none;
  font-weight: 400;
  color: var(--light);
}

.spcsubheading {
  padding: 1% 0;
  margin-top: 3%;
  color: var(--white);
  font-size: var(--h2);
}

.spcsubheadingDes {
  font-size: var(--h3);
  margin-bottom: 5%;
}

.quartz-block-product {
  margin: 5% 0;
}

.quartz-block {
  color: var(--light);
  /* font-size: 15px; */
}

.quart-icon {
  color: var(--success);
  margin-left: 5px;
}

.quartz-inquiry-heading {
  color: var(--light);
  font-size: var(--h1);
  text-align: left;
}

.QuartzInfoBox {
  /* margin-bottom: 5%; */
  width: 75%;
  /* background-color: black; */
}

@media screen and (max-width: 770px) {
  .quartz-img {
    padding: 2% 2%;
    height: 300px;
  }

  .quartz-info-details {
    width: 95%;
    margin: 4% 2%;
    align-items: center;
    justify-content: center;
  }

  .quartz-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1% 0%;
    padding: 0 10% 0 10%;
  }

  .quartz-inquiry-heading {
    margin: 2% 0;
  }
}

/* QUARTZINQUIERY CSS END*/

/* body {
  background-color: #444442;
} */

h1 {
  font-weight: 600;
  font-size: 72px;
  text-align: center;
}

h4 {
  font-weight: 400;
  font-size: 20px;
  line-height: 1.5;
}

/* ///// inputs /////*/

input:focus~label,
textarea:focus~label,
input:valid~label,
textarea:valid~label {
  font-size: 1em;
  color: var(--cream);
  top: -5px;
  -webkit-transition: all 0.225s ease;
  transition: all 0.225s ease;
}

.styled-input {
  float: left;
  width: 293px;
  margin: 0.5rem 0;
  position: relative;
  border-radius: 4px;
}

.styled-input label {
  color: var(--white);
  padding: 0.5rem 30px 1rem 30px;
  position: absolute;
  top: 10px;
  left: 0;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  pointer-events: none;
}

.styled-input.wide {
  width: 650px;
  max-width: 100%;
}

.input-container input,
textarea {
  height: 50px;
  margin: 1% 0;
  padding: 1% 3% 0;
  padding-top: 5%;
  border: 0;
  width: 100%;
  font-size: 15px;
  color: var(--white);
  background-color: var(--pencil);
  border-radius: 4px;
}

input:focus,
textarea:focus {
  outline: 0;
}

input:focus~span,
textarea:focus~span {
  width: 100%;
  -webkit-transition: all 0.075s ease;
  transition: all 0.075s ease;
}

textarea {
  width: 100%;
  min-height: 8em;
  padding: 5.5% 2% 0;
}

.proInquiryForm {
  /* display: flex;
  justify-content: center; */
  margin-top: 5%;
}

.input-container {
  /* width: 400px; */
  width: 100%;
}

.formInquiryCaption {
  color: var(--cream);
  font-size: var(--h4);
  margin: 5% 0%;
}

.submit-btn {
  padding: 7px 35px;
  margin-bottom: 5vmax;
  width: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  background-color: var(--white);
  color: var(--dark);
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.06), 0 2px 10px 0 rgba(0, 0, 0, 0.07);
  -webkit-transition: all 300ms ease;
  transition: all 0.5s ease;
}

.submit-btn:hover {
  background-color: var(--pencil);
  color: var(--white);
  transition: 0.5s;
}

@media (max-width: 768px) {
  .submit-btn {
    width: 100%;
    float: none;
    text-align: center;
    border-radius: 15px;
  }

  .product-inquiry-form {
    width: 100%;
  }

  .styled-input {
    width: 100%;
  }

  .input-container {
    max-width: 100%;
  }

  .wallItemsImagBox:hover .hoverImages {
    opacity: 0;
  }

  .wallItemsImagBox:hover .hoverImagesQuartz {
    opacity: 0;
  }
}

/* LVT_PRODUCT-INQUIRY CSS START */

.lvt-product-desc {
  border-left: 1px dotted var(--cream);
  margin-bottom: 4%;
  padding: 0% 4%;
  display: flex;
  flex-direction: column;
}

.lvt-heading {
  color: var(--light);
  font-size: 22px;
  text-transform: capitalize;
}

@media screen and (max-width: 800px) {
  .lvt-product-desc {
    border-left: none;
    border-bottom: 1px dotted var(--cream);
    margin: 4% 0;
  }
}

@media screen and (max-width: 820px) {
  .footerImageLogo {
    width: 30vmax;
  }

  .footerImageApp {
    width: 20vmax;
  }
}

.quartz-overview{
  color: var(--cream);
  margin-top: 10%;
}