:root {
  --light: #ede7dd;
  --brown: #6d5f56;
  --cream: #cac3bb;
  --pencil: #666666;
  --dark: #2f2f2f;
  --white: #ffffff;
  --black: #2f2f2f;
  --success: green;
  --logoColor: #7a8390;
  --red: red;
  --h1: 25px;
  --h2: 17px;
  --h3: 15px;
}