/* Navigation CSS Start */

.hideNavHeader {
  display: none;
}
.navRowheader {
  width: 100%;
  height: 90px;
}
.logo {
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  color: var(--cream);
}
.logoTitle {
  font-size: 22px;
  color: var(--cream);
}
.logoName{
  width: 80px;
  height: 54px;
  margin-top: 5%;
}

.bottomBorder {
  border-bottom: 1px solid var(--pencil);
}
.navAddress {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.navAddressDescription {
  color: var(--cream) !important;
  font-size: 18px;
}
.cnt-icon {
  color: var(--cream) !important;
  margin-right: 10px;
  font-size: 30px;
}
.img-logo {
  height: 60px;
}

@media only screen and (max-width: 760px) {
  .right-top-nav {
    display: none;
  }
  .navigationTop {
    display: none;
  }
  .img-logo1 {
    display: none;
  }
  .bottom-border {
    border: 0px;
  }
}

/* Navigation CSS End */

/* SLIDER CSS START*/

/* .imageDisplay {
  height: 500px;
} */

.zoom {
  height: 500px;
}

@keyframes zoom {
  0% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1, 1);
  }
}

@media screen and (max-width: 800px) {
  .imageDisplay {
    height: 550px;
  }
  .navAddress {
    display: none;
  }
  .hideMobile {
    display: none;
  }
  .NavbuttonRight {
    float: right;
  }
}
/* SLIDER CSS END*/
