.bg-image {
    background: url("https://admin.argiltiles.com/public/slider/1661685400927088234.jpg") no-repeat;
    background-size: cover;
    background-color: grey;
    background-blend-mode: multiply;
    color: #CAC3BB;
    padding: 0 6% 6% 6%;
}

.bg-image-enhance {
    background: url("../../../Assets/enhance/enhance-bg.jpg") no-repeat;
    background-size: 100% 100%;
    background-color: grey;
    background-blend-mode: multiply;
    color: #CAC3BB;
    padding: 0 8% 8% 8%;
}

.enhance-bg {
    margin-top: 5%;
    position: relative;
    width: 100%;
    height: auto;
}

.enhance-text {
    position: absolute;
    font-size: 2rem;
    text-align: center;
    width: 100%;
    top: 0;
    padding-top: 10px;
    color: var(--light);
}


@media only screen and (max-width: 768px) {
    .bg-image-enhance {
        background-size: cover;
    }
}