.titleBox {
  background-color: var(--cream);
  color: var(--brown);
  letter-spacing: 2px;
  font-size: 20px;
  height: 58px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  /* box-shadow: 2px 2px 2px var(--default); */
  display: flex;
  align-items: center;
  padding-left: 20px;
}
.titleBox a {
  color: var(--dark) !important;
}
.custom-header-home:hover {
  color: var(--pencil);
}

@media only screen and (max-width: 800px) {
  .titleBox {
    height: 80px;
    justify-content: center;
    padding: 0;
    margin: 0;
  }
}
