.advertiseRoot {
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 8vmax;
  left: 2vmax;
}
.advertiseBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.closeAdveriseIcon {
  align-self: flex-start;
  background-color: rgba(255, 255, 255, 0.3);
  color: var(--white);
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
  margin-top: -3vmax;
  border-radius: 8px;
  padding: 0.8vmax;
  transition: all 0.5s;
}

.advertiseImg {
  width: 31vmax;
  display: flex;
  border-radius: 10px;
}
@media screen and (max-width: 800px) {
  .advertiseRoot {
    top: 20vmax;
  }
}
